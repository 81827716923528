<!--
Public: a video component to play a video as well as add subtitles and chapters from the given Post

name: This should match 'target' in <show-hide-icon>

Example:
<video-base
    video-url="http://videoUrl.com/example"
    video-type="video/mp4"
    vtt-file="http://exampleVtt.vtt"
    user-id="<%= current_member.id %>"
    post-id="<%= post.id %>"
    asset-id="<%= post.webinar.id %>"
    most-recent-timecode="<%= MediaView.most_recent_view(user_id: current_member.id, post_id: post.id).try(:end_timecode) %>"
    cover-image="<%= Functions::Posts::Hero.hero_image(post) %>"
    >
</video-base>
-->
<template>
  <div class="mb-5">
    <div class="position-relative overflow-hidden"
         data-component-type="video"
         data-video-id="webinar-video">
      <div v-if="!currentlyPlaying && present(coverImage)"
           @click="playVideo"
           class="pointer position-absolute ui-z-index-9 d-flex align-items-center justify-content-center w-100 h-100">
        <i class="bi bi-play-circle ui-z-index-8 video-overlay-play-icon position-absolute"></i>
        <img :src="coverImage"
             class="video-overlay-img w-100 h-100 rounded-4 ui-z-index-7 position-relative" />
      </div>
      <video crossorigin="anonymous"
             id="webinar-video"
             ref="video"
             class="w-100 rounded-4"
             controls>
        <source :src="videoUrl" :type="videoType" />
        <track v-if="vttFile" label="English" kind="subtitles" srclang="en" :src="vttFile">
      </video>
    </div>
  </div>
</template>

<script>
  import { post }           from '../lib/async.js'
  import { blank, present } from '../lib/utils.js'
  import { mapState }       from 'vuex'

  export default {
    props: {
      // Public: Id of media from Assets table.
      assetId:            { default: null },
      // Public: Image to show if the video is not playing.
      coverImage:         { default: null },
      // Public: Timecode of the most recent view (if any) for the given user/post combo from the db.
      mostRecentTimecode: { default: null },
      // Public: Id of post being viewed from ruby.
      postId:             { default: 0 },
      // Public: url of the video to play.
      videoUrl:           { default: '' },
      // Public: file type of video
      videoType:          { default: '' },
      // Public: url for .vtt file
      vttFile:            { default: '' }
    },

    data() {
      return {
        // The start of the current range within the video the user watched.
        playRangeStart:   0,
        // The end of the current range within the video the user watched.
        playRangeEnd:     0,
        // Whether or not the video is currently playing, used to correctly log or not log views when navigating away from the page.
        currentlyPlaying: false,
      }
    },

    computed: {
      ...mapState([
        'currentUser'
      ]),
    },

    methods: {
      present: present,

      // Get the most recent MediaView record for the given user for the given video (if any)
      // and if the record is under 24 hours old skip to that time in the video.
      skipToRecentViewProgress() {
        if (blank(this.mostRecentTimecode)) return

        // Setting current start data property and current timecode of video element.
        this.$refs.video.currentTime = this.mostRecentTimecode
      },

      // Runs when a play event is triggered.
      videoPlayEventListener(event) {
        this.playRangeStart   = event.target.currentTime
        this.currentlyPlaying = true
      },

      // Runs when a pause event is triggered.
      videoPauseEventListener(event) {
        this.playRangeEnd      = event.target.currentTime
        this.currentlyPlaying  = false
        const viewDuration     = this.playRangeEnd - this.playRangeStart

        // A quick play/pause click can result in a 0 duration view attempting to be logged.
        if (viewDuration > 0) {
          post('/api/media-view/log-media-view', {
            user_id:        this.currentUser.memberId,
            post_id:        this.postId,
            asset_id:       this.assetId,
            view_duration:  viewDuration,
            start_timecode: this.playRangeStart,
            end_timecode:   this.playRangeEnd
          })
        }
      },

      // Runs when a before unload event is triggered.
      beforeUnloadEventListener() {
        if (!this.currentlyPlaying) return
        let body           = new FormData()
        const viewDuration = this.$refs.video.currentTime - this.playRangeStart

        body.append("user_id",        this.currentUser.memberId)
        body.append("post_id",        this.postId)
        body.append("asset_id",       this.assetId)
        body.append("view_duration",  viewDuration)
        body.append("start_timecode", this.playRangeStart)
        body.append("end_timecode",   this.$refs.video.currentTime)

        // Add CSRF Token to api call.
        const param     = document.querySelector("meta[name=csrf-param]").getAttribute("content")
        const csrfToken = document.querySelector("meta[name=csrf-token]").getAttribute("content")
        body.append(param, csrfToken)

        // A play click event -> immediate navigation away can result in a 0 duration view attempting to be logged.
        if (viewDuration > 0) {
          navigator.sendBeacon("/api/media-view/log-media-view", body)
        }
      },

      // Runs to manually trigger a play event when video overlay image is clicked
      playVideo() {
        if (this.currentlyPlaying) return

        this.currentlyPlaying  = true

        this.$refs.video.play()
      }
    },

    mounted() {
      // All items related to video view tracking in this if block
      //
      // We aren't tracking views for non-logged in users of the site.
      if (present(this.currentUser?.memberId)) {
        this.$refs.video.addEventListener("play", this.videoPlayEventListener)

        // *** The video reaching the end ALSO triggers this event listener. ***
        this.$refs.video.addEventListener("pause", this.videoPauseEventListener)

        window.addEventListener("beforeunload", this.beforeUnloadEventListener)

        this.skipToRecentViewProgress()
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/colors.scss";

  .video-overlay-play-icon {
    color: white;
    font-size: 5rem;
    text-shadow: $dark-gray 1px 0 10px;
  }

  .video-overlay-img {
    padding-bottom: .3rem;
  }
</style>
