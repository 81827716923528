import isBoolean   from 'lodash/isBoolean'
import isNumber    from 'lodash/isNumber'
import isObject    from 'lodash/isObject'
import isString    from 'lodash/isString'
import isNull      from 'lodash/isNull'
import last        from 'lodash/last'
import reduce      from 'lodash/reduce'
import snakeCase   from 'lodash/snakeCase'
import sumBy       from 'lodash/sumBy'
import values      from 'lodash/values'
import {trim}      from './string.js'
import postModel   from '../models/post.js'
import moment      from 'moment'

// Public: Add commas to a number.
//
// number - Number to format.
//
// Example:
//   addCommas(1) // => '1'
//   addCommas(1000) // => '1,000'
//   addCommas(1000000) // => '1,000,000'
//
// Returns a string version of the nubmer with commas added.
export function addCommas(number) {
  if (nullOrUndefined(number)) {
    return ''
  }

  var parts = number.toString().split('.')
  parts[0]  = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return parts.join('.')
}

// Public: Returns true if the given object is not present and false otherwise.
//
// object - Is this object blank?
//
// Returns a boolean.
export function blank(object) {
  if(nullOrUndefined(object)) {
    return true
  }

  if(typeof object == 'string') {
    return trim(object) === ''
  }

  if(object.length != undefined && object.length == 0) {
    return true
  }

  return false
}

// Public: Copy the given text to the clipboard.
//
// value - value to be copied.
export function copyToClipboard(value) {
  const el = document.createElement('textarea')

  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

// Public: Take a block of HTML and truncate the count of lines that display on the screen
//
// htmlString - String of Html
// lineCount  - Count of lines to truncate to
export function truncateHtmlLines(htmlString, lineCount) {
  // Regular expressions to match <meta>, <title>, and <style> tags and their content
  const metaRegex = /<meta[^>]*>/gis;   // <meta> tags are usually self-closing
  const titleRegex = /<title[^>]*>.*?<\/title>/gis;
  const styleRegex = /<style[^>]*>.*?<\/style>/gis;

  let removedContent = "";

  // Extract and remove content from the specified tags
  let result = extractAndRemoveHtml(htmlString, metaRegex, removedContent);
  htmlString = result.htmlString;
  removedContent = removedContent + result.removedContent;

  result = extractAndRemoveHtml(htmlString, titleRegex, removedContent);
  htmlString = result.htmlString;
  removedContent = removedContent + result.removedContent;

  result = extractAndRemoveHtml(htmlString, styleRegex, removedContent);
  htmlString = result.htmlString;
  removedContent = removedContent + result.removedContent;

  // Split the cleaned HTML into lines
  let lines = htmlString.split('\n');

  // Get the first `lineCount` lines
  let truncatedHtml = lines.slice(0, lineCount).join('\n');

  // Reattach the removed content at the end of the truncated HTML
  const finalHtml = removedContent + truncatedHtml;

  // Ensure the result is a new string, not a reference to any other part
  return finalHtml;
}

// Utility function to extract and remove HTML content using a specific regex
//
// Returns original (now cleaned) html string AND the content that was subtracted
export function extractAndRemoveHtml(htmlString, regex, removedContent) {
  const match = htmlString.match(regex);
  if (match) {
    removedContent = removedContent + match.join(' \n ');  // Store the removed content
    htmlString = htmlString.replace(regex, '');  // Remove the content from the original HTML
  }
  return { htmlString, removedContent };
}

// Public: Get a value from an options object or return a default value.
//
// options      - Object containing a value to return.
// optionName   - Key in the options object.
// defaultValue - Value to return if the optionName key is not available in the options object.
//
// Returns the value options[optionName] if available and defaultValue otherwise.
export function def(options, optionName, defaultValue) {
  if(!isObject(options)) {
    return null
  }

  return nullOrUndefined(options[optionName]) ? defaultValue : options[optionName]
}

// Public: Get either a value or a default value if the value is null or undefined.
//
// value        - Get this value if it is neither null nor undefined.
// defaultValue - Get this value if the value is null or undefined.
//
// Returns either value or defaultValue.
export function defaultValue(value, defaultValue) {
  return nullOrUndefined(value) ? defaultValue : value
}

// Public: Export the device operating system.
//
// Returns a String (Android, iOS, Windows, unknown)
export function device() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (['iPhone', 'iPad', 'iPod'].includes(navigator.platform)) return 'iOS'

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) return "Windows"

  if (/android/i.test(userAgent)) return "Android"

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS"

  // iPad's on iOS 13+ use the desktop version of safari, so require a bit more for detection.
  // https://stackoverflow.com/questions/57776001/how-to-detect-ipad-pro-as-ipad-using-javascript
  if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) return 'iPadOS'

  return "unknown"
}

// Public: Whether the device is mobile.
//
// Returns a Boolean
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi/i.test(navigator.userAgent)
      || typeof window.orientation !== "undefined"
      || navigator.userAgent.indexOf('IEMobile') !== -1
}

// Public: Returns true if the given object is null or undefined and false otherwise.
//
// object - Is this object null or undefined?
//
// Returns a boolean.
export function nullOrUndefined(object) {
  return (object == null || object == undefined)
}

// Public: Append a forward slash to the beginning of a url if it isn't already there.
//
// url - Append a forward slash to this string.
//
// Returns a string.
export function permalinkUrl(url) {
  return url.match(/^\//) ? url : '/' + url
}

// Public: Get the permalink URL for the given Post.
//
// post - A models.Post object.
//
// Returns a string.
export function postPermalinkUrl(post) {
  var permalink = permalinkUrl(post.permalink)

  if(postModel.isDiscussion(post)) {
    return '/discussions/' + last(permalink.split(/\//))
  }

  return permalink
}

// Public: Returns true if the given object is present and false otherwise.
//
// object - Is this object present?
//
// Returns a boolean.
export function present(object) {
  return !blank(object)
}

// Public: Resize a text area, if necessary, after the contents change.
//
// textArea - Textarea element
export function resizeTextAreaForElement(textArea) {
  // starting height is the client height or the scroll height, whichever is greater
  var adjustedHeight = textArea.clientHeight
  adjustedHeight     = Math.max(textArea.scrollHeight, adjustedHeight)

  // if the height used by the text content is greater, then resize
  if(adjustedHeight > textArea.clientHeight) {
    textArea.style.height = adjustedHeight + 'px'
  }
}

// Public: Highlight the text within the element provided. This saves a step
// for users intending to copy to the clipboard.
//
// element - Target element to highlight.
export function selectTextInElement(element) {
  if (window.getSelection) {
    let selection = window.getSelection()
    let range     = document.createRange()

    range.selectNodeContents(element)
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

// Public: Convert the keys in the given hash to snake case.
//
// hash - Convert the keys in this object to snake case.
//
// Returns a new hash with the keys converted to snake case.
export function snakeCaseKeys(hash) {
  return reduce(
    hash,
    function(object, value, key) {
      if(isObject(value)) {
        value = snakeCaseKeys(value)
      }

      object[snakeCase(key)] = value

      return object
    },
    {}
  )
}

// Public: Split the given an array into even number of columns and return the
// column requested
//
// list          - array to process
// totalColumns  - number of columns in which to split the list
// currentColumn - the column to return
//
// Returns an array
export function splitList(list, totalColumns, currentColumn) {
  if (currentColumn < 1) {
    currentColumn = 1
  }
  else if (currentColumn > totalColumns) {
    currentColumn = totalColumns
  }

  var columnLength = Math.ceil(list.length / totalColumns)
  var start        = (currentColumn - 1) * columnLength
  var end          = start + columnLength

  return list.slice(start, end)
}


// Public: Converts the given value to a boolean.
//
// value - Source to convert.
//
// Returns true of false.
export function toBoolean(value) {
  if (isBoolean(value)) { return value }
  if (isNumber(value))  { return value >= 1 }
  if (isString(value))   { return value.toLowerCase() == 'true' }
  if (isNull(value))    { return false }

  return false
}

// Public: Generates a random whole number for both the markup chart id to link to
// as well as the new Chart declaration inside of a function
//
// Single computed property running this function = same random number for chart and markup
//
// Returns a random whole number
export function randomId() {
  return Math.floor(Math.random() * 9999999999)
}

// Public: Filters out values from the current month.
//         Uses .isSame from moment.js to compare values
//         setup for filtering chartkick linechart arrays
//
// lineArray - dataset to filter
//
// Returns a filtered set of data
export function removeCurrentMonth(lineArray) {
  return lineArray.filter((lineValue)=>{
    if (!moment(lineValue[0]).isSame(new Date(), 'month')) {
      return lineValue
    }
  })
}

// Public: Returns the default avatar in the event the user being displayed doesn't have one.
//
// avatarUrl - prospective avatarUrl to check.
//
export function ensureAvatar(avatarUrl) {
  if (present(avatarUrl)) return avatarUrl

  return "https://content.i4cp.com/images/image_uploads/0000/3187/default-avatar.png"
}

// Public: Returns the sum of all values for a key value object
//
// hash - hash to sum
//
export function sumValues(hash) {
  // Uses 2 lodash functions to get the sum of all keys in the object
  return sumBy(values(hash))
}
