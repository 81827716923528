<template>
  <div :class="cssClass">
    <a class="fw-bold d-flex fs-6 py-3 px-3 justify-content-between align-items-center text-decoration-none text-white background-color-nav-blue">
      Collection details
    </a>
    <div class="background-color-lightest-gray p-3">
      <div v-html="html" :class="displayHtmlClass">
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center px-4"
           v-if="isGuest">
        <hr class="w-100 my-5" />
        <div class="color-nav-blue fw-bold text-center fs-4 mb-3">
          Read more content and access other resources from i4cp.
        </div>
        <div class="color-nav-blue d-block text-center mb-4 ui-fs-7">
          This content is available exclusively to i4cp members. Become an enterprise member to unlock these resources and
          <a class="fw-semibold" href="/solutions/membership/">other benefits</a> for your entire company.
        </div>
        <div class="w-50 mb-5">
          <a class="ui-dark-btn ui-md-btn pointer ui-fs-7"
             href="/contact">
              BECOME A MEMBER
          </a>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center px-4"
           v-if="!isGuest && !htmlUnderTruncateLength">
        <hr class="w-100 mt-2 mb-4" />
        <div @click="internalCollapsedBody = !internalCollapsedBody"
             class="text-decoration-underline color-link-blue fw-bold text-center pointer ui-max-w-15rem py-2">
          {{expandBtnText}}<i :class="expandBtnIcon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { truncateHtmlLines }   from '../lib/utils.js'

  export default {
    props: {
      // Prop reflecting whether or not a Post record shows the full collection when in a member facing context
      collapseDescription: { default: true },
      // CSS classes to apply to the div.
      cssClass:            { default: '' },
      // The full html to render.
      html:                { default: '' },
      // Boolean from Ruby regaring if the user can see the full dropdown or a guest version
      isGuest:             { default: true },
    },

    data: function() {
      return {
        // Public: Whether or not the html is the same length after truncation.
        htmlUnderTruncateLength: false,
        // Public: For i4cp users, whether or not the full text body is shown
        internalCollapsedBody:   true
      }
    },

    computed: {
      displayHtmlClass() {
        return this.isGuest || this.internalCollapsedBody ? "truncate-collection-preview-html" : ""
      },

      // Text displayed on the member facing more/less btn
      expandBtnText() {
        return this.internalCollapsedBody ? "More detail" : "Less detail"
      },

      // Icon for the expand button
      expandBtnIcon() {
        return this.internalCollapsedBody ? "ms-2 bi bi-chevron-down" : "ms-2 bi bi-chevron-up"
      }
    },

    mounted() {
      this.htmlUnderTruncateLength = truncateHtmlLines(this.html, 11).length === this.html.length
      this.internalCollapsedBody   = this.collapseDescription
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/breakpoints.scss";

  .truncate-collection-preview-html {
    display: -webkit-box;
    line-clamp: 11;
    -webkit-line-clamp: 11;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 23rem;

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xl) {
      max-height: 24.5rem;
    }

    @media (max-width: $breakpoint-md) {
      max-height: 39.5rem;
    }
  }
</style>
