// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'


// ----------------- marketing.js ----------------------------------

// require('intersection-observer');

// Bootstrap
// import 'bootstrap'
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
// document.addEventListener("DOMContentLoaded", ()=> {
//   var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//     return new bootstrap.Popover(popoverTriggerEl)
//   })
//
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })
// });

// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import VueCroppie from 'vue-croppie'
// import LocalTime  from 'local-time'
// import '../../assets/javascripts/vendor/marketing/google-fonts.js'

// LocalTime.start()


import * as Vue          from 'vue';

import {store}           from '../lib/marketing_store.js'

// import {member_dropdowns} from '../lib/member_dropdowns.js'

// import "chartkick/chart.js"

import Alert               from '../components/alert.vue'
import ContactFormSubmit   from '../components/contact_form_submit.vue'
import CookieBanner        from '../components/cookie_banner.vue'
import CountryDropdown     from '../components/country_dropdown.vue'
import CollectionPreview   from '../components/collection_preview.vue'
import ExpandSlide         from '../components/marketing/expand_slide.vue'
import FocusInput          from '../components/focus_input.vue'
import FormattedTime       from '../components/formatted_time.vue'
import Icon                from '../components/icon.vue'
import Impersonate         from '../components/impersonate.vue'
import MarketingPersonTile from '../components/marketing_person_tile.vue'
import MobileAppBanner     from '../components/mobile_app_banner.vue'
import NewsletterPopup     from '../components/newsletter_popup.vue'
import LazyImageLoader     from '../components/lazy_image_loader.vue'
import LogClick            from '../components/log_click.vue'
import Podcast             from '../components/podcast.vue'
import PostTile            from '../components/post_tile.vue'
import PressYears          from '../components/press_years.vue'
import ScrollInFromLeft    from '../components/scroll_in_from_left.vue'
import SimpleSearch        from '../components/simple_search.vue'
import ToggleRenderTags    from '../components/toggle_render_tags.vue'
import VideoBase           from '../components/video_base.vue'
import WorkingGroupLeads   from '../components/working_group_leads.vue'

document.addEventListener('DOMContentLoaded', () => {
  const app = Vue.createApp({
    components: {
      Alert,
      ContactFormSubmit,
      CookieBanner,
      CountryDropdown,
      CollectionPreview,
      ExpandSlide,
      FocusInput,
      FormattedTime,
      Icon,
      Impersonate,
      MarketingPersonTile,
      MobileAppBanner,
      NewsletterPopup,
      LazyImageLoader,
      LogClick,
      Podcast,
      PostTile,
      PressYears,
      ScrollInFromLeft,
      SimpleSearch,
      ToggleRenderTags,
      VideoBase,
      WorkingGroupLeads,
    },
  })

  app.use(store())
  app.mount('.marketing-content')
})
